/* outer */
.quiz-answer-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
}




.response-status {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.response {
    display: flex;
    gap: 8px;

}

.response-text {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.response-key {
    color: #0075FF;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
}

.status-correct {
    display: flex;
    padding: 8px 15px;
    align-items: center;

    border-radius: 40px;
    background: #32BD9C;
    font-size: 12px;
    font-weight: 500;
}

.response-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.response-status {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.responsebtn-desc {
    display: flex;
    flex-direction: column;
    gap: 18px;
    justify-content: start;
}

.response-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    padding: 8px;
}

.option-key {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;

}

.ticked-answer {
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
}

.response-description {
    color: #A0AEC0;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;

    display: flex;
    justify-content: start;
}



.status-false {
    display: flex;
    padding: 8px 15px;
    align-items: center;

    border-radius: 40px;
    background: #EC6767;
    font-size: 12px;
    font-weight: 500;
}