.all-ranking::-webkit-scrollbar {
    width: 8px;
    padding-right: 25px;

}

.all-ranking::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.10);
    border-radius: 6px;
    background-clip: content-box;
}

.all-ranking::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 8px;
    margin-left: 0px;
}
