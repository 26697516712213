/* HorizontalLoader.css */
.loader-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  .loader {
    width: 100%;
    height: 16px;
    border-radius: 9px;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.25);
  }
  
  .loader-fill {
    height: 100%;
    background-color: #0075FF;
    position: absolute;
    left: 0;
    transition: width 0.3s ease;
    border-radius: 9px;
  }

  .quiz-wrapper {
    display: flex;
    width: 100%;
  }