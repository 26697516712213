.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* src/App.css */
#root,
html,
body {
  margin: 0;
  padding: 0;
  height: fit-content;
  width: 100%;
}

.App {
  background-image: url("./Images/SiteBackground.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-position: center;
  height: fit-content;
  
}

p {
  color: white;
}

h3 {
  color: white;
}

.main {
  display: flex;
  height: fit-content;
  justify-content: space-between;
}

.page-header {
  width: 100%;
}
