.course-left-nav {
    display: flex;
    flex-direction: column;
    padding: 17px 20px;
    min-width: 30%;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: rgba(0, 0, 0, 0.65);
    height: calc(100vh - 123px); 
    overflow-y: scroll;
    overflow-x: hidden;
}

.course-left-nav::-webkit-scrollbar {
    width: 8px;
    padding-right: 25px;

}

.course-left-nav::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.10);
    border-radius: 6px;
    background-clip: content-box;
}

.course-left-nav::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 8px;
    margin-left: 0px;
}

@media (max-width: 480px) {
    /* Mobile devices */
    .course-left-nav{
      width: 100% !important;
    }
}
@media (max-width: 620px) {
    /* Mobile devices */
    .course-left-nav{
      width: 100% !important;
    }
}
@media (max-width: 768px) {
    .course-left-nav{
        width: 100% !important;
      }
  
  }
  @media (max-width: 1532px) {
    .course-left-nav{
        width: 100% !important;
        height: calc(100vh - 123px) !important; 
      }
  
  }