@tailwind base;
@tailwind components;
@tailwind utilities;

/* Your custom styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}



/* Style browser autocomplete suggestions */
input:-webkit-autofill {
  background-color: transparent !important; /* Keep the input black */
  color: #ffffff !important; /* Keep text white */
  -webkit-text-fill-color: #ffffff !important; /* Text color */
  transition: background-color 5000s ease-in-out 0s; /* Prevent flashing */
}

.react-datetime-picker__wrapper{
  border:none !important;
}
.react-datetime-picker__calendar{
  background-color: #060B26 !important;
  color: #060B26 !important;
}
.react-datetime-picker__clock{
  color: #060B26 !important;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  max-height: 100vh;
  max-width: 100vw;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #060B26;
}

code {
  font-family: 'Poppins', sans-serif;
}
.truncate-1-line {
  overflow: hidden ;
  display: -webkit-box !important;
  -webkit-box-orient: vertical ;
  -webkit-line-clamp: 1 ;
  text-overflow: ellipsis ;
}

.title {
  display: inline-block;
  padding: 30px 60px;
  background: #f4f4f4;
  border-radius: 8px;
  font-weight: normal;
  margin-bottom: 10px;
}
.plyr{
  position: absolute !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  z-index: 999999;
}