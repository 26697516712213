.course-nav-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: start;
    overflow-y:scroll;
    height: 100%;
}


.course-nav-wrapper::-webkit-scrollbar {
    width: 0px;
    padding-right: 10px;

}

.course-nav-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.10);
    border-radius: 6px;
    background-clip: content-box;
}

.course-nav-wrapper::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 8px;
    margin-left: 0px;
}



