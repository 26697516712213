.question-tracker-header {
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.question-tracker-header h2 {
    text-align: center;
}


.quiz-page-content {
    display: flex;
    height: calc(100vh - 150px);
    padding: 17px 22px;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;


    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: rgba(0, 0, 0, 0.65);

    overflow-y: scroll;
    overflow-x: hidden;
}

@media (min-width: 1024px){
    .quiz-page-content{
    height: calc(100vh - 123px);
    }
}


.quiz-page-content::-webkit-scrollbar {
    width: 8px;
    padding-right: 25px;

}

.quiz-page-content::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.10);
    border-radius: 6px;
    background-clip: content-box;
}

.quiz-page-content::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 8px;
    margin-left: 0px;
}



.next-button {
    display: flex;
    width: 70%;
    height: 56px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 12px;
    background: #0075FF;

    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 600px;
}

.next-button-bottom-wrapper {
    display: flex;
    justify-content: center;
}

.quiz-question-options h2 {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.question-options {
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 10px;
}

.quiz-question-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 100px;
    height: 100%;
}


.quiz-question-options-top {
    display: flex;
    gap: 30px;
    flex-direction: column;
}

.content-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.option-number {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    margin-right: 10px;
    transition: background 0.3s ease, color 0.3s ease;
}

.video-player {
    border-radius: 50px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.quiz-answer-video {
    border-radius: 9.83px;
    overflow: hidden;
    position: relative;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden;
}

.quiz-answer-content {
    overflow-y: scroll;
    overflow-x: hidden;
}


.quiz-answer-content::-webkit-scrollbar {
    width: 8px;
    padding-right: 25px;

}

.quiz-answer-content::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.10);
    border-radius: 6px;
    background-clip: content-box;
}

.quiz-answer-content::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 8px;
    margin-left: 0px;
}


.quiz-page-layout {
    display: flex;
    gap: 24px;
}


.course-left-nav {
    display: flex;
    width: 30%;
    height: 87vh;
}

.next-button-bottom {
    display: flex;
    justify-content: center;
}

.continue-btn {
    display: flex;
    width: 100%;
    height: 56px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 12px;

    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 600px;
}

.answer-response {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.completion-button-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.completion-btn {
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 8.567px;
    align-self: stretch;

    border-radius: 7.71px;
    background: linear-gradient(90deg, #113BE1 0%, #4268FF 50.48%, #4062E3 72.41%, #113BE1 100%);

    color: #FFF;
    font-size: 11.993px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;


}



.completion-sub-1 {
    color: #A0AEC0;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 132.885%;
    /* 15.946px */
    width: 70%;
}

.completion-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}