

.event-list {
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.event-list::-webkit-scrollbar {
    display: none;
}

